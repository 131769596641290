html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, Poppins;
  line-height: 1.6;
  font-size: 18px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #181C45;
}

* {
  box-sizing: border-box;
}

a {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: #13BCB4;
}

@media screen and (min-width: 359px) {
  a {
    font-size: 18px;
  }
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  padding-left: 40px;
}

input:focus::-webkit-input-placeholder 
{
    color: transparent;
}

#float-label:focus-within label {
  transform: translate(0, 18px) scale(0.75);
  transition: .2s cubic-bezier(.77,0,.175,1);
}

#__next {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  #__next {
    width: unset;
    /*justify-content: center;*/
  }
}

#scrollBarDiv {
  overflow-x: hidden !important;
}

#disabledScrollBarDiv {
  overflow-y: hidden !important;
}

#iconWrapper:hover {
  background-color: rgb(240,240,240);
  border-radius: 50%;
}

#downloadIconWrapper:hover {
  background-color: rgb(240,240,240);
  border-radius: 10%;
}

#back-icon:hover {
  background-color: rgb(240,240,240);
  border-radius: 50%;
}

#skip-question:hover {
  background-color: rgb(240,240,240);
}

.grecaptcha-badge {
  visibility: hidden;
}

#particle-canvas {
  display: block; 
  position: fixed;
  pointer-events: none; 
  left: 0; 
  top: 0; 
  width: 100%; 
  height: 100%;
  z-index: 4000;
  }